import React from "react"
import Helmet from 'react-helmet'
import SportsBar from '../components/SportsBar'
import FightList from '../components/FightList'
import queryString from 'query-string'
 

const FightsPage = (props) => {

  const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

  return (
    <>
      <Helmet>
        <title>Fanpowered Fights - Vote for the Fights You Want to See.</title>
      <meta name="description" content="We're on a mission to put the power back in the hands of the fans. Vote on the boxing fights you want to see." />
      </Helmet>
      <SportsBar sport="all" />
      <div className="sports-pages">
	      <FightList path={props.path} sport="all" login={parsed.login} />
      </div>
    </>
  )
}

export default FightsPage